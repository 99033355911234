import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Row, Col, Image } from "react-bootstrap"

//images import

import Tunnel1 from "./../images/tunneltaal/fp-tunneltaal.jpg"
import Tunnel2 from "./../images/tunneltaal/tunneltaal1.jpg"
import Tunnel3 from "./../images/tunneltaal/tunneltaal2.jpg"
import Tunnel4 from "./../images/tunneltaal/tunneltaal3.jpg"
import Tunnel5 from "./../images/tunneltaal/tunneltaal4.jpg"
import Tunnel6 from "./../images/tunneltaal/tunneltaal5.jpg"
import Tunnel7 from "./../images/tunneltaal/tunneltaal6.jpg"
import Tunnel8 from "./../images/tunneltaal/tunneltaal7.jpg"

const Tunneltaal = ({ location, id}) => (
  <Layout>
    {/* vervangen door propId */}
    <Seo title="Tunneltaal" 
    description="Tunneltaal, afstudeerwerk KASK Gent, Winnaar Henry Van de Velde Space Award 2022" />
    <div>
      <Row className="p-0">
        <Col xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-1">
        <h1 className="text-uppercase fs-4 text-dark">tunneltaal</h1>
                <p className="textAbout">The Tunneltaal project was part of my master’s project Het Sociale Spel (The Social game) in cooperation with the municipality of Rotselaar. In my study, I started from the question: what is the designer’s position in society? 
                    </p>
                    <p>
                    I argue that design should bridge the gap between everyday and other disciplines and that, as a designer, it is more interesting to involve other people in this. The tunnel at the center of the research lies between two youth homes. At night, the lights go out and the tunnel becomes an unpleasant place to drive through. I involved as many local young people as possible in my research: youth associations, young people from the neighborhood and the fifth- and sixth-year secondary school groups of Montfort College in Rotselaar. Each participant was given a template of the font designed by me and was able to apply it in and on the tunnel. In this way, during the day, you can read what is going on among the young people in the neighbourhood. Afterwards, a glow-in-the-dark paint was applied over the letters. The letters charge up with the sun’s (or moon’s) energy and release it at night. This gives the tunnel an extra function at night: it lights up. The tunnel is a metaphor for the time in which research was made a reality: connecting and illuminating in socially difficult times. The project was created in cooperation with photographer Benina Hu, filmmaker Floris Leempoels and illustrator Thorsten Staes.
                    </p>
        </Col>
      </Row>
      <Row className="text-center p-0">
        <Col  xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={Tunnel1} fluid style={{width: '100%'}} alt="Tunneltaal, Henry Van de Velde, Space Awards, Manon Lambeens"/>
        </Col>
      </Row>
      <Row className="text-center p-0">
        <Col xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-2">
        <Image src={Tunnel2} fluid style={{width: '100%'}} alt="Tunneltaal, Henry Van de Velde, Space Awards, Manon Lambeens"/>
        </Col>
      </Row>
      <Row className="text-center p-0">
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
         <Image src={Tunnel3} fluid alt="Tunneltaal, Henry Van de Velde, Space Awards, Manon Lambeens" />
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
         <Image src={Tunnel4} fluid alt="Tunneltaal, Henry Van de Velde, Space Awards, Manon Lambeens"/>
        </Col>
      </Row>
      <Row className="text-center p-0">
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
         <Image src={Tunnel5} fluid alt="Tunneltaal, Henry Van de Velde, Space Awards, Manon Lambeens"/>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={Tunnel6} fluid className="" alt="Tunneltaal, Henry Van de Velde, Space Awards, Manon Lambeens"/>
        </Col>
      </Row>
      <Row className="text-center p-0">
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
         <Image src={Tunnel8} fluid alt="Tunneltaal, Henry Van de Velde, Space Awards, Manon Lambeens"/>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={Tunnel7} fluid alt="Tunneltaal, Henry Van de Velde, Space Awards, Manon Lambeens"/>
        </Col>
      </Row>
    </div>
  </Layout>
)

export default Tunneltaal;